var parseData = require("../utils/parseData");
function Accordion(elm, options) {
    var $elm = $(elm);
    options = $.extend({closeOthers: false, slideTime: 0.5, slide: true}, options);

    function show() {
        $(".ui-accordion-body", $elm).each(function () {
            var h = getHeight($(this));
            if (h > 0) {
                $(this).data("height", h);
            }
            $(this).prev().toggleClass("closed", $(this).is(":hidden"));
        }).on({
            load: function () {
                $(this).data("height", getHeight($(this).closest(".ui-accordion-body")));
            }
        }, "img");
        $elm.removeClass("show").off({show: show});
        $(".ui-accordion-header", $elm).each(function () {
            if ($(this).hasClass("closed")) {
                $(this).next().css({display: "none", height: "0px"});
            } else {
                $(this).next().show();
            }
        });
    }

    if ($elm.closest(":hidden").length && !$elm.hasClass("show")) {
        $elm.addClass("show").on({
            show: function () {
                if ($elm.closest(":hidden").length == 0) {
                    show();
                }
            }
        });
    } else {
        show();
    }


    function getHeight($body) {
        var $c1 = $body.clone().css({
            visibility: "hidden", position: "relative",
            display: "block", left: "-99999px", height: ""
        }).insertAfter($body);
        var h = $c1.height();
        $c1.remove();
        return h;
    }

    function update($body, h) {
        if (h == 0) {
            $body.hide().trigger("ui.accordion.hideEnd");
        } else {
            $body.css({height: ""}).trigger("ui.accordion.showEnd");
            $body.find(".show").trigger("show");
        }
    }


    function updateHeight($body) {
        var h = getHeight($body);
        if (h > 0) {
            $body.data("height", h);
        }
    }

    function to($body, h) {
        $body.css({display: "block"});
        var hasHeight = !!$body.data("height");
        if (options.slide && hasHeight) {
            TweenLite.to($body, options.slideTime, {
                css: {height: h + "px"}, ease: Power2.easeOut, onComplete: function () {
                    update($body, h);
                }
            });
        } else {
            update($body, h);
        }

    }


    $elm.on({
        click: function (e) {
            if ($(e.target).closest("a,button").size() > 0) {
                return;
            }
            if ($(this).closest(".ui-accordion").get(0) != $elm.get(0)) {
                return;
            }
            var $body = $(this).next(),
                closed = $(this).hasClass("closed"),
                h;
            if (closed) {
                h = $body.data("height");
                if (h == 0) {
                    updateHeight($body);
                    h = $body.data("height");
                }
                $(this).removeClass("closed");
                $(this).next().trigger("ui.accordion.showStart");
            } else {
                updateHeight($body);
                $(this).addClass("closed");
                $(this).next().trigger("ui.accordion.hideStart");
                h = 0;
            }
            to($body, h);
            if (options.closeOthers && h > 0) {
                $elm.find(".ui-accordion-header:not(.closed)").not(this).each(function () {
                    to($(this).addClass("closed").next(), 0);
                });
            }
        }
    }, ".ui-accordion-header");

    $elm.data("ui", {});

}

$.fn.uiAccordion = function (options) {
    return $(this).each(function () {
        if ($(this).data("ui")) {
            return;
        }
        new Accordion(this, options);
    });
};